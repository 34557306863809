/*-------------------------
  theme colors
-------------------------*/
/* primary
-------------------------*/
/* secondary
-------------------------*/
/* natural
-------------------------*/
/* error
-------------------------*/
/*-------------------------
  common color
-------------------------*/
@font-face {
  font-family: "Noto Serif JP";
  src: url("/assets/fonts/jp/NotoSansJP-Regular.woff2") format("woff2"), url("/assets/fonts/jp/NotoSansJP-Regular.woff") format("woff"), url("/assets/fonts/jp/NotoSansJP-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "Noto Serif JP";
  src: url("/assets/fonts/jp/NotoSansJP-Bold.woff2") format("woff2"), url("/assets/fonts/jp/NotoSansJP-Bold.woff") format("woff"), url("/assets/fonts/jp/NotoSansJP-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
/* normal-italic*/
@font-face {
  font-family: "Noto Serif JP";
  src: url("/assets/fonts/jp/NotoSansJP-Regular.woff") format("woff2"), url("/assets/fonts/jp/NotoSansJP-Regular.woff") format("woff"), url("/assets/fonts/jp/NotoSansJP-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/en/Roboto-Regular.woff2") format("woff2"), url("/assets/fonts/en/Roboto-Regular.woff") format("woff"), url("/assets/fonts/en/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/en/Roboto-Bold.woff2") format("woff2"), url("/assets/fonts/en/Roboto-Bold.woff") format("woff"), url("/assets/fonts/en/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
/* normal-italic */
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/en/Roboto-Italic.woff") format("woff2"), url("/assets/fonts/en/Roboto-Italic.woff") format("woff"), url("/assets/fonts/en/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("/assets/fonts/en/MPLUS1-Regular.woff2") format("woff2"), url("/assets/fonts/en/MPLUS1-Regular.woff") format("woff"), url("/assets/fonts/en/MPLUS1-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("/assets/fonts/en/MPLUS1-Bold.woff2") format("woff2"), url("/assets/fonts/en/MPLUS1-Bold.woff") format("woff"), url("/assets/fonts/en/MPLUS1-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/en/Montserrat-Regular.woff2") format("woff2"), url("/assets/fonts/en/Montserrat-Regular.woff") format("woff"), url("/assets/fonts/en/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/en/Montserrat-Bold.woff2") format("woff2"), url("/assets/fonts/en/Montserrat-Bold.woff") format("woff"), url("/assets/fonts/en/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
}
@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
}
@keyframes hideimage {
  0% {
    transform: translateY(44px);
  }
  50% {
    transform: translateY(44px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes showimage {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(44px);
  }
}
.top .container {
  padding: 0;
}
@media screen and (min-width: 992px) {
  .top .container {
    max-width: none;
  }
}
@media screen and (min-width: 992px) {
  .top .content-inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
  }
}
.top .contbox {
  margin: 48px auto 56px;
}
@media screen and (min-width: 992px) {
  .top .contbox {
    margin: 80px auto 96px;
  }
  .top .contbox:last-child {
    margin-bottom: 80px;
  }
}
.top .visual {
  margin: -45px 0 0;
  aspect-ratio: 4/5;
  position: relative;
}
@media screen and (min-width: 992px) {
  .top .visual {
    height: 85vh;
    margin: -99px 0 0;
    aspect-ratio: auto;
  }
}
.top .visual-slide {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top .visual-slide-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.top .visual-slide-list li {
  width: 100%;
  height: 100vh;
}
.top .visual-slide-list .slick-list::before {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.08);
  z-index: 1;
  content: "";
}
.top .visual-slide-list img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: hideimage 8s linear forwards;
  display: block;
  aspect-ratio: 4/5;
}
@media screen and (min-width: 992px) {
  .top .visual-slide-list img {
    height: calc(85vh + 125px);
  }
}
.top .visual-slide-list .slick-current img {
  animation: showimage 8s linear forwards;
}
.top .visual-text {
  position: absolute;
  width: 14%;
  top: 16%;
  left: 9%;
}
@media screen and (min-width: 992px) {
  .top .visual-text {
    width: auto;
    top: 25.5%;
    left: 10%;
  }
}
.top .visual-btn {
  width: 170px;
  position: absolute;
  right: 12px;
  bottom: 12px;
}
@media screen and (min-width: 992px) {
  .top .visual-btn {
    width: 274px;
    right: 48px;
    bottom: 48px;
  }
}
.top .visual .slick-slider,
.top .visual .slick-list {
  max-width: none;
  border-radius: 0;
}
.top .information {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 992px) {
  .top .information {
    flex-wrap: nowrap;
  }
}
.top .information .infolink {
  width: 50%;
  padding: 17.5px 13px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  border-bottom: 1px solid #dee4eb;
}
@media (hover: hover) {
  .top .information .infolink:hover {
    opacity: 1;
    background-color: #e9f2fc;
  }
}
.top .information .infolink:nth-child(even) {
  border-left: 1px solid #dee4eb;
}
.top .information .infolink .infolink-icon {
  font-size: 64px;
}
.top .information .infolink .infolink-text {
  margin-top: 6.5px;
  margin-bottom: 27.5px;
}
.top .information .infolink .infolink-arrow {
  width: 48px;
  padding: 3px 0;
  font-size: 12px;
  background-color: #1e72c8;
  text-align: center;
  border-radius: 999px;
}
@media screen and (min-width: 992px) {
  .top .information .infolink {
    height: 274px;
    padding: 33px 10px 27px;
    justify-content: space-between;
  }
  .top .information .infolink .infolink-icon {
    font-size: 96px;
  }
  .top .information .infolink .infolink-text {
    margin-top: 0;
    font-size: 2rem;
    line-height: 1.75;
    letter-spacing: 0.03em;
  }
  .top .information .infolink .infolink-arrow {
    width: 66px;
    font-size: 18px;
  }
  .top .information .infolink:nth-child(odd):not(:first-child) {
    border-left: 1px solid #dee4eb;
  }
}
.top .news .caution {
  margin-bottom: 30px;
  padding: 16px 20px 26px;
  border-radius: 12px;
  background-color: #fef6f6;
}
@media screen and (min-width: 992px) {
  .top .news .caution {
    margin-bottom: 32px;
    padding: 38px 48px 45px;
    display: flex;
    gap: 40px;
    align-items: flex-start;
  }
}
.top .news .caution .caution-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #ec1e14;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 992px) {
  .top .news .caution .caution-title {
    margin-bottom: 0;
  }
}
.top .news .caution .caution-title .icon {
  font-size: 24px;
}
.top .news .caution .caution-title .icon::before {
  top: 0.07em;
}
@media screen and (min-width: 992px) {
  .top .news .caution .caution-title .icon {
    font-size: 28px;
  }
  .top .news .caution .caution-title .icon::before {
    margin-right: 8px !important;
  }
}
.top .news .caution .tab-wrapper {
  margin: 32px auto 24px;
}
@media screen and (min-width: 992px) {
  .top .news .notice {
    padding: 40px 40px 56px;
    border: 8px solid #f8f9fb;
    border-radius: 12px;
  }
}
.top .news .notice .notice-title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.75;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 992px) {
  .top .news .notice .notice-title {
    font-size: 2.9rem;
    line-height: 1.75;
    letter-spacing: 0.03em;
  }
}
.top .department {
  padding: 48px 0 56px;
  background-color: #f8f9fb;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .top .department {
    padding: 80px 0 96px;
  }
}
.top .department .department-head {
  margin-bottom: 20px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #4a627e;
}
@media screen and (min-width: 992px) {
  .top .department .department-head {
    margin-bottom: 40px;
  }
}
.top .department .department-date {
  margin-bottom: 17px;
}
.top .department .department-date time {
  color: #1e72c8;
  font-weight: bold;
}
@media screen and (min-width: 992px) {
  .top .department .department-date {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1.75;
    letter-spacing: 0.03em;
  }
}
.top .department .department-title {
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.75;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 992px) {
  .top .department .department-title {
    font-size: 2.9rem;
    line-height: 1.75;
    letter-spacing: 0.03em;
  }
}
.top .department .department-more {
  position: relative;
  color: #737373;
  font-size: 1.4rem;
}
.top .department .department-more::before {
  content: "";
  width: 1px;
  height: 39px;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  background-color: #dee4eb;
}
@media screen and (min-width: 992px) {
  .top .department .department-more::before {
    content: none;
  }
}
.top .department .department-more::after {
  top: 0.07em;
}
@media screen and (min-width: 992px) {
  .top .department .department-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
}
@media screen and (min-width: 1360px) {
  .top .department .department-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.top .department .department-list .department-rehabilitation {
  display: none;
}
.top .department .department-list .department-list-link {
  width: 153px;
  padding: 14px 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #dee4eb;
  border-radius: 8px;
  gap: 15px;
  line-height: 1;
  background-color: #fff;
  overflow: hidden;
}
.top .department .department-list .department-list-link.top-dept-box--closed {
  background-color: #fef6f6;
}
@media (hover: hover) {
  .top .department .department-list .department-list-link:hover {
    opacity: 1;
    background-color: #e9f2fc;
  }
}
@media screen and (min-width: 992px) {
  .top .department .department-list .department-list-link {
    width: 100%;
    height: 88px;
    gap: 14px;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }
  .top .department .department-list .department-list-link::after {
    margin-right: 10px;
    top: 0;
  }
}
.top .department .department-list .department-list-icon {
  width: 80px;
  height: 80px;
  padding: 4px 0;
  background-color: #1e72c8;
  border-radius: 10px;
  text-align: center;
  font-size: 70px;
  display: flex;
  align-items: center;
  color: #fff;
}
.top .department .department-list .department-list-icon::after {
  top: 0;
}
@media screen and (min-width: 992px) {
  .top .department .department-list .department-list-icon {
    width: 88px;
    height: 88px;
    font-size: 62.33px;
    border-radius: 0;
    padding: 14px 0;
  }
}
.top .department .department-list .department-list-textbox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .top .department .department-list .department-list-textbox {
    gap: 8px;
    text-align: left;
  }
}
.top .department .department-list .department-list-name {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.25;
}
.top .department .department-list .department-list-time {
  font-size: 1.4rem;
  color: #737373;
}
.top .department .department-list .department-list-time .closed {
  color: #ec1e14;
}
.top .department .department-foot {
  margin-top: 25px;
}
@media screen and (min-width: 992px) {
  .top .department .department-foot {
    margin-top: 29px;
  }
}
.top .department .department-foot .department-foot-link {
  padding: 7px 12px;
  position: relative;
}
.top .department .department-foot .department-foot-link .icon::before {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff9f5;
  font-size: 36px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top .department .department-foot .department-foot-link::after {
  content: "\e915";
  font-family: "potaicon";
  color: #fb893d;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
@media (hover: hover) {
  .top .department .department-foot .department-foot-link:hover {
    opacity: 1;
    background-color: #fff9f5;
  }
}
@media screen and (min-width: 992px) {
  .top .department .department-foot .department-foot-link {
    padding: 19px 23px;
  }
  .top .department .department-foot .department-foot-link::before {
    margin-right: 19px;
  }
  .top .department .department-foot .department-foot-link::after {
    right: 24px;
  }
}
.top .department .tab-label-wrap {
  width: 100%;
  margin: 0 auto 14px;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .top .department .tab-label-wrap {
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .top .department .tab-label-wrap .swich-container {
    width: 246px;
  }
}
.top .department .tab-label-item {
  border: none;
  background-color: transparent;
}
@media (hover: hover) {
  .top .department .tab-label-item:hover {
    background-color: transparent;
  }
}
.top .department .tab-label-item.js-tab-current {
  background-color: transparent;
}
.top .department .swiper-department {
  overflow: visible;
}
.top .department .swiper-department .swiper-slide {
  width: 153px;
}
.top .department .swiper-department .swiper-slide:not(:last-child) {
  margin-right: 8px;
}
.top .department .swiper-department .swiper-tools {
  margin-top: 17px;
}
@media screen and (min-width: 992px) {
  .top .department .swiper-department {
    overflow: auto;
    border-bottom: 1px solid #dee4eb;
  }
  .top .department .swiper-department .swiper-slide {
    width: 100%;
    margin-right: 0;
  }
  .top .department .swiper-department .swiper-tools {
    margin-top: 32px;
  }
}
.top .officials .officials-list {
  line-height: 0;
}
@media screen and (min-width: 992px) {
  .top .officials .officials-list {
    display: flex;
  }
  .top .officials .officials-list li {
    flex: 1;
  }
}
.top .officials .officials-list .officials-list-link {
  width: 100%;
  position: relative;
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  font-size: 2rem;
  line-height: 1.75;
  letter-spacing: 0.03em;
  display: block;
}
@media (hover: hover) {
  .top .officials .officials-list .officials-list-link:hover img {
    transform: scale(1.04);
  }
}
.top .officials .officials-list .officials-list-link img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.2s ease-out;
}
@media screen and (min-width: 992px) {
  .top .officials .officials-list .officials-list-link img {
    height: 360px;
  }
}
.top .officials .officials-list .officials-list-link span {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-85%);
  z-index: 2;
}
@media screen and (min-width: 992px) {
  .top .officials .officials-list .officials-list-link span {
    left: 40px;
    font-size: 2.3rem;
  }
}
.top .officials .officials-list .officials-list-link::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.top .officials .officials-list .officials-list-link .icon {
  width: 28px;
  height: 28px;
  background-color: #fff;
  font-size: 14px;
  border-radius: 50%;
  position: absolute;
  top: auto;
  right: 17px;
  bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
@media screen and (min-width: 992px) {
  .top .officials .officials-list .officials-list-link .icon {
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;
    font-size: 20px;
  }
}
.top .pickup {
  overflow: hidden;
}
.top .pickup .pickup-title {
  margin-bottom: 20px;
  padding-bottom: 12px;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.75;
  letter-spacing: 0.03em;
  border-bottom: 1px solid #4a627e;
}
@media screen and (min-width: 992px) {
  .top .pickup .pickup-title {
    margin-bottom: 48px;
    font-size: 2.9rem;
    line-height: 1.75;
    letter-spacing: 0.03em;
  }
}
.top .pickup .pickup-swiper {
  overflow: visible;
}
.top .pickup .pickup-swiper .swiper-tools {
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .top .pickup .pickup-swiper .swiper-tools {
    margin-top: 56px;
  }
}
.top .pickup .pickup-box {
  width: 282px;
}
.top .pickup .pickup-box:not(:last-child) {
  margin-right: 24px;
}
.top .pickup .pickup-box-link {
  display: inline-block;
  position: relative;
}
@media (hover: hover) {
  .top .pickup .pickup-box-link:hover .pickup-box-picture img {
    transform: scale(1.04);
  }
}
.top .pickup .pickup-box-link p {
  font-size: 1.4rem;
  line-height: 1.75;
  letter-spacing: 0.03em;
}
.top .pickup .pickup-box-picture {
  margin-bottom: 22px;
  border-radius: 4px;
  overflow: hidden;
}
.top .pickup .pickup-box-picture img {
  transition: transform 0.2s ease-out;
}
.top .pickup .pickup-box-title {
  margin-bottom: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  position: relative;
  letter-spacing: -0.03em;
}
.top .pickup .pickup-box-title::before {
  content: "";
  width: 32px;
  height: 1px;
  background-color: #1e72c8;
  position: absolute;
  left: 0;
  bottom: 0;
}
.top .pickup .pickup-box-num {
  padding-bottom: 14px;
  display: block;
  color: #fb893d;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
}
@media screen and (min-width: 992px) {
  .top .pickup .pickup-box-num {
    padding-bottom: 12px;
  }
}
.top .pickup .pickup-swiper-button {
  display: none;
}
@media screen and (min-width: 992px) {
  .top .pickup .pickup-swiper-button {
    display: flex;
  }
  .top .pickup .pickup-swiper-button .pickup-prev,
  .top .pickup .pickup-swiper-button .pickup-next {
    width: 4.8rem;
    height: 4.8rem;
    border: 1px solid #dee4eb;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-out;
  }
  .top .pickup .pickup-swiper-button .pickup-prev::after,
  .top .pickup .pickup-swiper-button .pickup-next::after {
    font-family: "potaicon";
  }
}
@media screen and (min-width: 992px) and (hover: hover) {
  .top .pickup .pickup-swiper-button .pickup-prev:hover,
  .top .pickup .pickup-swiper-button .pickup-next:hover {
    background-color: #e9f2fc;
  }
}
@media screen and (min-width: 992px) {
  .top .pickup .pickup-swiper-button .pickup-prev::after {
    content: "\e90d";
    color: #1e72c8;
    font-size: 2.6rem;
    top: 0;
  }
}
@media screen and (min-width: 992px) {
  .top .pickup .pickup-swiper-button .pickup-next {
    margin-left: 0.8rem;
  }
  .top .pickup .pickup-swiper-button .pickup-next::after {
    content: "\e90e";
    color: #1e72c8;
    font-size: 2.6rem;
    top: 0;
  }
}
.top .pickup .swiper-pagination {
  margin-top: 0;
}
@media screen and (min-width: 992px) {
  .top .pickup .swiper-pagination {
    margin-left: 26.5px;
  }
}