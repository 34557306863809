@charset 'UTF-8';
@use '../global/' as *;

.top {
  // common
  .container {
    padding: 0;
    @include media(lg) {
      max-width: none;
    }
  }
  .content-inner {
    @include media(lg) {
      width: 100%;
      max-width: calc(1200px + 80px);
      margin: 0 auto;
      padding: 0 40px;
    }
  }
  .contbox {
    margin: 48px auto 56px;
    @include media(lg) {
      margin: 80px auto 96px;
      &:last-child {
        margin-bottom: 80px;
      }
    }
  }

  .visual {
    margin: -45px 0 0;
    aspect-ratio: 4 / 5;
    position: relative;
    @include media(lg) {
      height: 85vh;
      margin: -99px 0 0;
      aspect-ratio: auto;
    }
    &-slide {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &-list {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        li {
          width: 100%;
          height: 100vh;
        }
        .slick-list {
          &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.08);
            z-index: 1;
            content: '';
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          animation: hideimage 8s linear forwards;
          display: block;
          aspect-ratio: 4 / 5;
          @include media(lg) {
            height: calc(85vh + 125px);
          }
        }
        .slick-current img {
          animation: showimage 8s linear forwards;
        }
      }
    }
    &-text {
      position: absolute;
      width: 14%;
      top: 16%;
      left: 9%;
      @include media(lg) {
        width: auto;
        top: 25.5%;
        left: 10%;
      }
    }
    &-btn {
      width: 170px;
      position: absolute;
      right: 12px;
      bottom: 12px;
      @include media(lg) {
        width: 274px;
        right: 48px;
        bottom: 48px;
      }
    }
    // slick slider
    .slick-slider,
    .slick-list {
      max-width: none;
      border-radius: 0;
    }
  }

  // information
  .information {
    display: flex;
    flex-wrap: wrap;
    background: $white;
    position: relative;
    z-index: 1;
    @include media(lg) {
      flex-wrap: nowrap;
    }
    .infolink {
      width: 50%;
      padding: 17.5px 13px 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      line-height: 1;
      border-bottom: 1px solid $border-color;
      @media (hover: hover) {
        &:hover {
          opacity: 1;
          background-color: $bg-hovered;
        }
      }
      &:nth-child(even) {
        border-left: 1px solid $border-color;
      }
      .infolink-icon {
        font-size: 64px;
      }
      .infolink-text {
        margin-top: 6.5px;
        margin-bottom: 27.5px;
      }
      .infolink-arrow {
        width: 48px;
        padding: 3px 0;
        font-size: 12px;
        background-color: $primary;
        text-align: center;
        border-radius: 999px;
      }
      @include media(lg) {
        height: 274px;
        padding: 33px 10px 27px;
        justify-content: space-between;
        .infolink-icon {
          font-size: 96px;
        }
        .infolink-text {
          margin-top: 0;
          @include fontsize(20);
        }
        .infolink-arrow {
          width: 66px;
          font-size: 18px;
        }
        &:nth-child(odd):not(:first-child) {
          border-left: 1px solid $border-color;
        }
      }
    }
  }

  // news
  .news {
    .caution {
      margin-bottom: 30px;
      padding: 16px 20px 26px;
      border-radius: 12px;
      background-color: $bg-important;
      @include media(lg) {
        margin-bottom: 32px;
        padding: 38px 48px 45px;
        display: flex;
        gap: 40px;
        align-items: flex-start;
      }
      .caution-title {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        color: $important;
        font-weight: bold;
        @include fontsize(16);
        @include media(lg) {
          margin-bottom: 0;
        }
        .icon {
          font-size: 24px;
          &::before {
            top: 0.07em;
          }
          @include media(lg) {
            font-size: 28px;
            &::before {
              margin-right: 8px !important;
            }
          }
        }
      }
      .tab-wrapper {
        margin: 32px auto 24px;
      }
    }
    .notice {
      @include media(lg) {
        padding: 40px 40px 56px;
        border: 8px solid $border-subtle;
        border-radius: 12px;
      }
      .notice-title {
        margin-bottom: 20px;
        font-weight: bold;
        @include fontsize(20);
        @include media(lg) {
          @include fontsize(29);
        }
      }
    }
  }

  // department
  .department {
    padding: 48px 0 56px;
    background-color: $bg-default;
    overflow: hidden;
    @include media(lg) {
      padding: 80px 0 96px;
    }
    .department-head {
      margin-bottom: 20px;
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-strong;
      @include media(lg) {
        margin-bottom: 40px;
      }
    }
    .department-date {
      margin-bottom: 17px;
      time {
        color: $primary;
        font-weight: bold;
      }
      @include media(lg) {
        margin-bottom: 0;
        @include fontsize(20);
      }
    }
    .department-title {
      margin: 0;
      font-weight: bold;
      @include fontsize(20);
      @include media(lg) {
        @include fontsize(29);
      }
    }
    .department-more {
      position: relative;
      color: $subtle;
      font-size: 1.4rem;
      &::before {
        content: '';
        width: 1px;
        height: 39px;
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
        background-color: $border-color;
        @include media(lg) {
          content: none;
        }
      }
      &::after {
        top: 0.07em;
      }
    }
    .department-list {
      @include media(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
      }
      @include media(xl) {
        grid-template-columns: repeat(4, 1fr);
      }
      // リハビリテーション科 非表示
      .department-rehabilitation {
        display: none;
      }
      .department-list-link {
        width: 153px;
        padding: 14px 8.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid $border-color;
        border-radius: 8px;
        gap: 15px;
        line-height: 1;
        background-color: $white;
        overflow: hidden;

        &.top-dept-box--closed {
          background-color: $bg-important;
        }

        @media (hover: hover) {
          &:hover {
            opacity: 1;
            background-color: $bg-hovered;
          }
        }
        @include media(lg) {
          width: 100%;
          height: 88px;
          gap: 14px;
          padding: 0;
          flex-direction: row;
          justify-content: space-between;
          &::after {
            margin-right: 10px;
            top: 0;
          }
        }
      }
      .department-list-icon {
        width: 80px;
        height: 80px;
        padding: 4px 0;
        background-color: $primary;
        border-radius: 10px;
        text-align: center;
        font-size: 70px;
        display: flex;
        align-items: center;
        color: $white;
        &::after {
          top: 0;
        }
        @include media(lg) {
          width: 88px;
          height: 88px;
          font-size: 62.33px;
          border-radius: 0;
          padding: 14px 0;
        }
      }
      .department-list-textbox {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
        text-align: center;
        @include media(lg) {
          gap: 8px;
          text-align: left;
        }
      }
      .department-list-name {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.25;
      }
      .department-list-time {
        font-size: 1.4rem;
        color: $subtle;
        .closed {
          color: $important;
        }
      }
    }
    .department-foot {
      margin-top: 25px;
      @include media(lg) {
        margin-top: 29px;
      }
      .department-foot-link {
        padding: 7px 12px;
        position: relative;
        .icon {
          &::before {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $bg-highlight;
            font-size: 36px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &::after {
          content: $arrow-right;
          font-family: $icon-font-family;
          color: $secondary;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }
        @media (hover: hover) {
          &:hover {
            opacity: 1;
            background-color: $bg-highlight;
          }
        }
        @include media(lg) {
          padding: 19px 23px;
          &::before {
            margin-right: 19px;
          }
          &::after {
            right: 24px;
          }
        }
      }
    }
    .tab-label-wrap {
      width: 100%;
      margin: 0 auto 14px;
      padding: 0;
      @include media(lg) {
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .swich-container {
          width: 246px;
        }
      }
    }
    .tab-label-item {
      border: none;
      background-color: transparent;
      @media (hover: hover) {
        &:hover {
          background-color: transparent;
        }
      }

      &.js-tab-current {
        background-color: transparent;
      }
    }
    .swiper-department {
      overflow: visible;
      .swiper-slide {
        width: 153px;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      .swiper-tools {
        margin-top: 17px;
      }
      @include media(lg) {
        overflow: auto;
        border-bottom: 1px solid $border-color;
        .swiper-slide {
          width: 100%;
          margin-right: 0;
        }
        .swiper-tools {
          margin-top: 32px;
        }
      }
    }
  }

  // officials
  .officials {
    .officials-list {
      line-height: 0;
      @include media(lg) {
        display: flex;
        li {
          flex: 1;
        }
      }
      .officials-list-link {
        width: 100%;
        position: relative;
        color: $white;
        font-weight: bold;
        overflow: hidden;
        @include fontsize(20);
        display: block;
        @media (hover: hover) {
          &:hover {
            img {
              transform: scale(1.04);
            }
          }
        }
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          transition: transform $base-transition;
          @include media(lg) {
            height: 360px;
          }
        }
        span {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-85%);
          z-index: 2;
          @include media(lg) {
            left: 40px;
            font-size: 2.3rem;
          }
        }
        &::after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.2);
          z-index: 1;
        }
        .icon {
          width: 28px;
          height: 28px;
          background-color: $white;
          font-size: 14px;
          border-radius: 50%;
          position: absolute;
          top: auto;
          right: 17px;
          bottom: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          @include media(lg) {
            width: 40px;
            height: 40px;
            right: 20px;
            bottom: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }

  // pickup
  .pickup {
    overflow: hidden;
    .pickup-title {
      margin-bottom: 20px;
      padding-bottom: 12px;
      font-weight: bold;
      @include fontsize(20);
      border-bottom: 1px solid $border-strong;
      @include media(lg) {
        margin-bottom: 48px;
        @include fontsize(29);
      }
    }

    //swiper
    .pickup-swiper {
      overflow: visible;
      .swiper-tools {
        margin-top: 40px;
        @include media(lg) {
          margin-top: 56px;
        }
      }
    }
    .pickup-box {
      width: 282px;
      &:not(:last-child) {
        margin-right: 24px;
      }
      &-link {
        display: inline-block;
        position: relative;
        @media (hover: hover) {
          &:hover {
            .pickup-box-picture {
              img {
                transform: scale(1.04);
              }
            }
          }
        }
        p {
          @include fontsize(14);
        }
      }
      &-picture {
        margin-bottom: 22px;
        border-radius: 4px;
        overflow: hidden;
        img {
          transition: transform $base-transition;
        }
      }
      &-title {
        margin-bottom: 16px;
        padding-bottom: 16px;
        font-weight: bold;
        position: relative;
        letter-spacing: -0.03em;
        &::before {
          content: '';
          width: 32px;
          height: 1px;
          background-color: $primary;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      &-num {
        padding-bottom: 14px;
        display: block;
        color: $secondary;
        font-family: $base-font-family-montserrat;
        font-size: 1.4rem;
        @include media(lg) {
          padding-bottom: 12px;
        }
      }
    }
    .pickup-swiper-button {
      display: none;
      @include media(lg) {
        display: flex;
        .pickup-prev,
        .pickup-next {
          width: 4.8rem;
          height: 4.8rem;
          border: 1px solid $border-color;
          border-radius: 50%;
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color $base-transition;
          &::after {
            font-family: $icon-font-family;
          }
          @media (hover: hover) {
            &:hover {
              background-color: $bg-hovered;
            }
          }
        }
        .pickup-prev {
          &::after {
            content: $chevron-left;
            color: $primary;
            font-size: 2.6rem;
            top: 0;
          }
        }
        .pickup-next {
          margin-left: 0.8rem;
          &::after {
            content: $chevron-right;
            color: $primary;
            font-size: 2.6rem;
            top: 0;
          }
        }
      }
    }
    .swiper-pagination {
      margin-top: 0;
      @include media(lg) {
        margin-left: 26.5px;
      }
    }
  }
}
